import React, { Fragment, useEffect } from "react";
import { useLocation } from "@reach/router";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

function Redirect() {
	const location = useLocation();
	function isValidURL(string) {
		let url;

		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const url = params.get("url");
		if (url && isValidURL(url)) {
			window.location.href = url;
		} else {
			window.location.href = "/";
		}
	}, []);
	return (
		<Fragment>
			<Helmet>
				<meta content="noindex" name="robots" />
			</Helmet>
			<Box alignItems="center" display="flex" height="100vh" justifyContent="center" width="100vw">
				{" "}
				<CircularProgress size={70} />
			</Box>
		</Fragment>
	);
}

export default Redirect;
